.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #aa004f;
  color: #fff;
}
::selection {
  background: #aa004f;
  color: #fff;
}
* {
  -webkit-tap-highlight-color: rgba(170, 0, 79, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((0.875rem + 0.1875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 0.875rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: url("../images/bg.png") #fff 50% 50% fixed;
  color: #141414;
  font-family: "Noto Sans", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 400;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.53;
  margin: 0 auto;
  max-width: 120rem;
  overflow-x: hidden;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(0.875rem + 0.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.0625rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.75rem + 0.5625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.75rem;
  color: #006E43;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 1.53em;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.75rem + 0.5625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1 {
    font-size: 2.3125rem;
  }
}
h1 em {
  font-size: 85%;
  font-weight: 400;
}
h2 {
  letter-spacing: calc((1.3125rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.3125rem;
  color: #141414;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 1.53em;
  padding-top: 0.765em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.3125rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2 {
    font-size: 1.5625rem;
  }
}
h3 {
  letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.25rem;
  color: #141414;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 1.53em;
  padding-top: 0.765em;
}
@media (min-width: 20rem) {
  h3 {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3 {
    font-size: 1.25rem;
  }
}
h4 {
  letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.25rem;
  color: #141414;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 1.53em;
  padding-top: 0.765em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 1.25rem;
  }
}
h5 {
  letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.25rem;
  color: #141414;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 1.53em;
  padding-top: 0.765em;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1.25rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 1.53em;
}
a {
  color: #aa004f;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a:active,
a.active {
  color: #141414;
  outline: 0;
}
a:focus {
  outline: 0.125rem solid #aa004f;
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
  top: -2.5rem;
}
a[tabindex="-1"][id]:after {
  content: "";
  display: none;
}
a.touch_link {
  color: #141414;
  display: block;
  text-decoration: none;
}
a.touch_link .title {
  color: #aa004f;
  display: inline-block;
  margin-bottom: 0.3825em;
  outline-offset: 0;
  padding: 0;
  text-decoration: underline;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}
a.touch_link .title.active {
  color: #141414;
}
a.touch_link:focus {
  outline: 0;
}
a.touch_link:focus .title {
  outline: 0.125rem solid #aa004f;
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a.touch_link:focus[data-focus-method="mouse"] .title,
a.touch_link:focus[data-focus-method="touch"] .title {
  outline: 0;
}
a.touch_link:hover .title {
  text-decoration: none;
}
.highlighted {
  -webkit-animation: highlighted_anchor 900ms;
          animation: highlighted_anchor 900ms;
}
strong {
  font-weight: 700;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.765em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.53em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.53em;
}
table {
  border-bottom: 0.125rem solid #dedede;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.125rem solid #dedede;
  padding: 0.51em;
  -webkit-transition: background 300ms;
  transition: background 300ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f7f7f7;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.765em;
  padding: 0;
}
ol > li {
  padding-left: 1.53em;
  position: relative;
}
ol > li:before {
  color: #aa004f;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.53;
  position: absolute;
  text-align: right;
  width: 1.1475em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: none!important;
  margin: 0 0 0.765em;
  padding-left: 1.1475em;
}
ul > li {
  padding-left: 0.3825em;
  position: relative;
}
ul > li:before {
  background: #aa004f;
  content: "";
  display: block;
  height: 0.5625rem;
  left: -0.765em;
  position: absolute;
  width: 0.5625rem;
  top: 0.375rem;
}
@media (min-width: 20rem) {
  ul > li:before {
    top: calc(0.375rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  ul > li:before {
    top: 0.625rem;
  }
}
ul > li:nth-child(2n+0):before {
  background: #752157;
}
ul > li:nth-child(3n+0):before {
  background: #006e43;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list_entry dl {
  border-bottom: 0.125rem solid #dedede;
  margin: 0.765em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list_entry dt {
  border-top: 0.125rem solid #dedede;
  font-weight: 700;
  padding: 0.765em 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.765em 0.765em 0.765em 0;
  }
}
.defined_list_entry dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list_entry dd {
    border-top: 0.125rem solid #dedede;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.765em 0.51em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none!important;
  margin: 0;
  padding: 0;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.53em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.53em;
}
figure.floatright {
  float: right;
  margin-left: 1.53em;
}
figcaption {
  font-size: 85%;
  margin-top: 0.3825em;
  text-align: left;
}
.figure_overview {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.figure_overview figure {
  margin-right: 1.53em;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-size: 1.0625rem;
  font-weight: 400;
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #141414;
  box-shadow: 0 0 0 62.5rem #fff inset, 0 0 0.375rem #aa004f inset, 0 0 0 0.375rem rgba(170, 0, 79, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #545454;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #545454;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #545454;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #aa004f;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #aa004f;
  color: #fff;
  display: none;
  font-weight: 700;
  margin-right: 0.3825em;
  padding: 0 0.3825em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.1875rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.1875rem;
  color: #141414;
  font-family: "Noto Serif", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.765em;
  padding: 0.765em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.1875rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.4375rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.3825em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff;
  border: 0.125rem solid #dedede;
  border-radius: 0.1875rem;
  box-shadow: 0 0 0 #dedede inset;
  color: #141414;
  margin: 0 0 1.02em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #aa004f;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #aa004f;
  box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
  color: #141414;
}
.input:focus,
.textarea:focus {
  border-color: #aa004f;
  box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
  color: #141414;
}
.input {
  height: 2.75rem;
  padding: 0 0.765em;
  -webkit-transition: border 300ms;
  transition: border 300ms;
}
.textarea {
  line-height: 1.53;
  overflow: hidden;
  padding: 0.765em;
  resize: none;
  -webkit-transition: border 300ms, height 300ms;
  transition: border 300ms, height 300ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.3825em 0.765em 0.765em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #aa004f;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #aa004f;
  box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
  color: #141414;
}
.control input:focus ~ .indicator {
  border-color: #aa004f;
  box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
  color: #141414;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.765em;
  position: relative;
}
.control .indicator {
  background: #fff;
  border: 0.125rem solid #dedede;
  border-radius: 0.1875rem;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #141414;
  height: 1.25rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  visibility: hidden;
  width: 1.25rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #fff;
  border: 0.125rem solid #dedede;
  border-radius: 0.1875rem;
  color: #141414;
  cursor: pointer;
  display: block;
  margin: 0 0 1.02em;
  padding: 0 0.765em;
  position: relative;
  -webkit-transition: border 300ms;
  transition: border 300ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #141414;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.765em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #aa004f;
}
.select.error.focus {
  border-color: #aa004f;
  box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
  color: #141414;
}
.select.focus {
  border-color: #aa004f;
  box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
  color: #141414;
}
.select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #141414;
  cursor: pointer;
  height: calc(2.75rem - (0.125rem * 2));
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.select select::-ms-expand {
  display: none;
}
.select select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #141414;
}
.select select:focus::-ms-value {
  background: none;
  color: #141414;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 110, 67, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(0, 110, 67, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(0, 110, 67, 0.1);
    box-shadow: 0 0 0 1.25rem rgba(0, 110, 67, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.53em;
    padding-top: 0.765em;
  }
  * + [role="tablist"] {
    margin-top: 1.53em;
  }
  [role="tab"] {
    letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    -webkit-box-align: center;
            align-items: center;
    border-top: 0.125rem solid #dedede;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.255em;
    min-width: 100%;
    padding: 0.51em 0;
  }
  [role="tab"] .title {
    flex-basis: 100%;
    margin-right: 0.765em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .description {
    letter-spacing: calc((0.875rem + 0.1875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    font-family: "Noto Sans", sans-serif;
    font-weight: normal;
    padding-top: 0.765em;
    display: inline-block;
  }
  [role="tab"] .icon {
    display: block;
    fill: #aa004f;
    height: 1.5rem;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #aa004f;
  }
  [role="tab"][aria-expanded="true"] .icon {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  [role="tab"]:focus {
    border-color: #aa004f;
    outline: 0;
  }
  [role="tab"]:focus .title {
    color: #aa004f;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.53em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 600ms, max-height 600ms;
    transition: margin 600ms, max-height 600ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.295em;
  }
  .contact_wrapper .address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .contact strong {
    min-width: 1.5625rem;
  }
  .address_vcard_button {
    margin: 0.765em 0;
  }
  .address_call_button {
    margin: 0.765em 0;
  }
  aside.aside {
    padding-bottom: 2.8125rem;
    display: none;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_wrapper {
    height: 1.1875rem;
    margin-top: 0.625rem;
    position: relative;
    margin-bottom: 0.625rem;
  }
  .author_hor {
    display: block;
    fill: #a0a0a0;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 0.9375rem;
    top: 0;
    width: 4.6875rem;
  }
  .breadcrumbs {
    display: none;
  }
  .button {
    border-radius: 0.1875rem;
    font-size: 1.0625rem;
    padding: 0 1.53em;
    background: #aa004f;
    border: 0.125rem solid #aa004f;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.3825em 0;
  }
  .button .icon + .text {
    padding-left: 0.51em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.1875rem rgba(170, 0, 79, 0.2);
    outline: 0;
    background: #dd0067;
    border-color: #dd0067;
    color: #fff;
  }
  .button:focus .icon {
    fill: #fff;
  }
  .button:hover {
    background: #dd0067;
    border-color: #dd0067;
    color: #fff;
  }
  .button:hover .icon {
    fill: #fff;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.1875rem #aa004f inset;
    background: #aa004f;
    border: 0.125rem solid #aa004f;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #fff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.3825em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.51em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #006e43;
    color: #fff;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    outline-color: #141414;
    overflow: hidden;
    padding: 0.3825em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #fff;
    height: auto;
    left: 0.3825em;
    top: 0.3825em;
    width: auto;
  }
  .steuernews_call_to_action {
    background: #f7f7f7;
    border-radius: 0.1875rem;
    display: inline-block;
    margin: 0 0 1.53em;
    padding: 0.765em;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .company_logo {
    max-width: 10.3125rem;
    display: block;
    width: 100%;
  }
  .company_logo.company_logo_1 {
    margin-left: 0;
  }
  .company_logo.company_logo_2 {
    outline-color: #752157;
  }
  .company_logo.company_logo_3 {
    margin-right: 0;
    outline-color: #006e43;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #006e43;
    border-top: 0.125rem solid #006e43;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner a {
    color: #fff;
    outline-color: #fff;
  }
  .cookie_banner p {
    color: #fff;
    flex-basis: 100%;
    margin: 0;
    padding: 1.02em 0.765em 1.02em 1.53em;
  }
  .cookie_banner .close {
    cursor: pointer;
    margin-right: 0.765em;
    padding: 0 0.765em;
  }
  .cookie_banner .close > span {
    height: auto;
    min-height: calc((2.75rem / 3) - (0.125rem * 2));
  }
  .cookie_banner .close .text {
    padding: 0.153em 0;
  }
  .cookie_banner_padding {
    display: none;
  }
  .cb_visible .cookie_banner_padding {
    display: block;
  }
  .download_overview {
    margin-bottom: 0.765em;
  }
  .download_overview .touch_link {
    display: -webkit-box;
    display: flex;
  }
  .download_overview .touch_link .title_wrapper {
    margin-bottom: 0.3825em;
    padding-top: 0;
  }
  .download_overview .icon {
    flex-shrink: 0;
    height: 1.5rem;
    margin-right: 0.765em;
    width: 1.5rem;
  }
  .download_overview .icon_doc {
    fill: #2a5699;
  }
  .download_overview .icon_xls {
    fill: #4e892d;
  }
  .download_overview .icon_pdf {
    fill: #e44e4e;
  }
  .download_overview .icon_online {
    fill: #0074ce;
  }
  .dropdown_menu {
    display: none;
  }
  .dropdown_menu > ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
  }
  .dropdown_menu > ul > li {
    -webkit-box-align: center;
            align-items: center;
    cursor: pointer;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li:after {
    letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #aa004f;
    content: "|";
    font-weight: 700;
    padding-right: 0.3125rem;
  }
  .dropdown_menu > ul > li:nth-child(2):after,
  .dropdown_menu > ul > li:nth-child(5):after {
    color: #752157;
  }
  .dropdown_menu > ul > li:nth-child(2) ul,
  .dropdown_menu > ul > li:nth-child(5) ul {
    background: rgba(117, 33, 87, 0.95);
  }
  .dropdown_menu > ul > li:nth-child(3):after {
    color: #006e43;
  }
  .dropdown_menu > ul > li:nth-child(3) ul {
    background: rgba(0, 110, 67, 0.95);
  }
  .dropdown_menu > ul > li:last-child:after {
    display: none;
  }
  .dropdown_menu > ul > li:hover > a:after,
  .dropdown_menu > ul > li.hover > a:after {
    left: 0;
    opacity: 1;
    right: 0;
    visibility: visible;
  }
  .dropdown_menu > ul > li:hover ul,
  .dropdown_menu > ul > li.hover ul {
    margin-top: 0.765em;
    opacity: 1;
    visibility: visible;
  }
  .dropdown_menu > ul > li > a {
    letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #141414;
    font-weight: 700;
    margin: 0;
    outline-color: #141414;
    outline-offset: 0.19125em;
    padding: 0.1275em 0;
    position: relative;
    text-decoration: none;
    margin-left: 0.9375rem;
    margin-right: 0.9375rem;
  }
  .dropdown_menu > ul > li > a:after {
    background: #141414;
    border-radius: 0.1875rem;
    bottom: 0;
    content: "";
    display: block;
    height: 0.125rem;
    left: 50%;
    opacity: 0;
    position: absolute;
    right: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .dropdown_menu > ul > li > a.active:after,
  .dropdown_menu > ul > li > a:focus:after,
  .dropdown_menu > ul > li > a:hover:after {
    left: 0;
    opacity: 1;
    right: 0;
    visibility: visible;
  }
  .dropdown_menu > ul > li ul {
    background: rgba(170, 0, 79, 0.95);
    border-radius: 0.1875rem;
    left: 50%;
    margin-left: -0.51em;
    opacity: 0;
    padding: 1.53em 0;
    position: absolute;
    text-align: center;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: hidden;
    z-index: 1000;
  }
  .dropdown_menu > ul > li ul:target {
    margin-top: 0.765em;
    opacity: 1;
    visibility: visible;
  }
  .dropdown_menu > ul > li ul a {
    color: #fff;
    display: block;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    outline: 0;
    padding: 0.153em 1.53em;
    text-decoration: none;
    white-space: nowrap;
  }
  .dropdown_menu > ul > li ul a.active,
  .dropdown_menu > ul > li ul a:hover,
  .dropdown_menu > ul > li ul a:focus {
    text-decoration: underline;
  }
  .page_footer {
    overflow: hidden;
  }
  .page_footer .google_maps {
    margin: 0;
  }
  .page_footer .google_maps h2 {
    display: none;
  }
  .page_footer .google_maps .map {
    height: 50vh;
    min-height: 37.5rem;
  }
  .page_footer .google_maps_wrapper {
    position: relative;
  }
  .page_footer .google_maps_overlay_wrapper {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    left: 0;
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .page_footer .google_maps_overlay {
    background: #752157;
    color: #fff;
    padding: 1.53em;
  }
  .page_footer .google_maps_overlay .address {
    margin-bottom: 0.51em;
  }
  .page_footer .google_maps_overlay .address .contact_info .contact {
    display: block;
  }
  .page_footer .google_maps_overlay .address .contact_info .contact .label {
    display: block;
    margin-bottom: 0;
  }
  .page_footer .google_maps_overlay a {
    color: #fff;
  }
  .page_footer .google_maps_overlay h2 {
    color: #fff;
    font-weight: 400;
    margin-bottom: 0.765em;
  }
  .page_footer .google_maps_overlay h2.first {
    padding: 0;
  }
  .page_footer .google_maps_overlay p {
    margin-bottom: 1.02em;
  }
  .page_footer .bottom {
    background: #f7f7f7;
    padding: 7.65em 0 4.59em;
  }
  .page_footer .company_name {
    color: #006e43;
  }
  .page_footer h3 {
    font-weight: 400;
    margin-bottom: 0.765em;
  }
  .page_footer a {
    color: #141414;
    outline-color: #141414;
  }
  .page_footer .links_overview {
    margin: 0;
  }
  .page_footer .links_overview a {
    text-decoration: none;
    white-space: nowrap;
  }
  .page_footer .links_overview a:hover {
    text-decoration: underline;
  }
  .page_footer .links_overview ul {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    margin: 0;
    padding: 0;
  }
  .page_footer .links_overview ul li {
    padding: 0;
  }
  .page_footer .links_overview ul li:before {
    display: none;
  }
  .page_footer .links_overview ul li:first-child:after {
    content: "|";
    display: inline-block;
    padding-left: 0.5rem;
    padding-right: 0.625rem;
  }
  .page_footer .flex_container {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .page_footer .flex_container .first {
    margin-right: 3.06em;
  }
  .google_maps {
    margin: 0 0 0.765em;
  }
  .google_maps .map {
    background: #f7f7f7;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.765em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #dedede;
    border-color: #d1d1d1;
    color: #141414;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #141414;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
    z-index: 15;
    background: #dd0067;
    border-color: #910043;
    color: #fff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #fff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #fff;
    border: 0.125rem solid #dedede;
    color: #141414;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.75rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #141414;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.3825em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.51em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #141414;
    min-width: 1.5rem;
    -webkit-transition: fill 300ms;
    transition: fill 300ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0.1875rem 0 0 0.1875rem;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0.1875rem 0.1875rem 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0.1875rem;
    color: #aa004f;
    font-size: 85%;
    margin-bottom: 0.765em;
    padding: 1.02em 1.53em;
  }
  .google_maps .direction {
    margin: 0.765em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.765em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.125rem solid #dedede;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.125rem solid #dedede;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.765em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.765em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.765em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .page_header {
    padding: 2.295em 0;
    position: relative;
  }
  .page_header .container {
    position: relative;
  }
  .page_header:before {
    background: #fff;
    bottom: 0;
    content: "";
    display: block;
    left: -6249.9375rem;
    position: absolute;
    right: -6249.9375rem;
    top: 0;
    z-index: -1;
  }
  .page_header .flex_container {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: end;
            justify-content: flex-end;
    position: absolute;
    right: 0.9375rem;
    top: 0;
  }
  .page_header .call {
    display: block;
    height: 3.125rem;
    line-height: 0;
    width: 3.125rem;
  }
  .page_header .call .icon {
    display: block;
    height: 3.125rem;
    width: 3.125rem;
  }
  .image_wrapper {
    position: relative;
  }
  .image_wrapper:before {
    background: #f7f7f7;
    bottom: 0;
    content: "";
    display: block;
    left: -6249.9375rem;
    position: absolute;
    right: -6249.9375rem;
    top: 0;
    z-index: -1;
  }
  .header_picture {
    position: relative;
  }
  .header_picture img {
    display: block;
    margin: 0 auto;
  }
  .header_picture .call {
    bottom: -3.125rem;
    color: #fff;
    display: none;
    height: 10.9375rem;
    left: 63%;
    position: absolute;
    text-decoration: none;
    width: 10.9375rem;
  }
  .header_picture .call .bg1 {
    background: #fff;
    border: 0.125rem solid #aa004f;
    border-radius: 50%;
    display: block;
    height: 11.6875rem;
    left: 50%;
    margin-left: -5.8125rem;
    margin-top: -5.875rem;
    position: absolute;
    top: 50%;
    width: 11.6875rem;
    z-index: 2;
  }
  .header_picture .call .bg2 {
    background: #fff;
    border: 0.125rem solid #006e43;
    border-radius: 50%;
    display: block;
    height: 12.4375rem;
    left: 50%;
    margin-left: -6.1875rem;
    margin-top: -6.25rem;
    position: absolute;
    top: 50%;
    width: 12.4375rem;
    z-index: 1;
  }
  .header_picture .call .text {
    letter-spacing: calc((0.875rem + 0.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.875rem;
    -webkit-box-align: center;
            align-items: center;
    background: #752157;
    border-radius: 50%;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
            justify-content: center;
    line-height: 1.35;
    padding: 0.765em;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 3;
  }
  .header_picture .call .inner {
    display: block;
    max-width: 100%;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.765em;
    padding-top: 0.765em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.612em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.765em;
  }
  main {
    padding-bottom: 2.8125rem;
  }
  .disclaimer {
    background: #f7f7f7;
    border-radius: 0.1875rem;
    margin-bottom: 0.765em;
    padding: 1.02em 1.53em;
  }
  .disclaimer p {
    margin-bottom: 0;
  }
  .overview a.entry {
    background: #f7f7f7;
    border: 0.125rem solid #dedede;
    border-radius: 0.1875rem;
    margin-bottom: 0.765em;
    outline: 0!important;
    padding: 1.224em 1.53em;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .overview a.entry .title {
    margin: 0;
    text-decoration: none;
    -webkit-transition: 300ms;
    transition: 300ms;
  }
  .overview a.entry:focus,
  .overview a.entry:hover {
    border-color: #aa004f;
    outline: 0;
  }
  .overview a.entry:focus .title,
  .overview a.entry:hover .title {
    padding-left: 0.51em;
  }
  body.startpage h1 {
    line-height: 1.1;
    text-align: center;
  }
  body.startpage .teaser_overview.first .teaser {
    padding-bottom: 0;
  }
  .start_text {
    margin: 0 auto;
    max-width: 63.4375rem;
    text-align: center;
  }
  .start_text a {
    color: #141414;
    outline-color: #141414;
  }
  .slider_overview {
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 100%;
  }
  .slider_overview .slider {
    overflow: hidden;
    position: relative;
  }
  .slider_overview .slide {
    display: block;
    opacity: 0;
    position: absolute;
    text-align: center;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    width: 100%;
  }
  .slider_overview .slide.active {
    opacity: 1;
  }
  .slider_overview .career_banner {
    height: 100%;
    position: absolute;
    width: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
  }
  .slider_overview .career_banner .career_banner_content {
    max-width: 43.75rem;
    background-color: rgba(0, 110, 67, 0.8);
    border-bottom: 0.125rem solid #752157;
    border-top: 0.125rem solid #aa004f;
    color: #fff;
    padding: 0.765em 1.53em;
  }
  .slider_overview .career_banner .career_banner_content h2,
  .slider_overview .career_banner .career_banner_content h3,
  .slider_overview .career_banner .career_banner_content p {
    margin-bottom: 0.765em;
    color: #fff;
  }
  .slider_overview .career_banner .career_banner_content .contact {
    display: block;
  }
  .slider_overview .career_banner .career_banner_content .contact .additional_info {
    display: none;
  }
  .slider_overview .career_banner .career_banner_content .greetings {
    display: none;
  }
  .slider_overview .control_slides {
    -webkit-box-align: center;
            align-items: center;
    background: #f7f7f7;
    display: -webkit-box;
    display: flex;
    fill: #141414;
    height: 3.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin-top: -1.875rem;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transition: background 300ms, opacity 300ms, visibility 300ms;
    transition: background 300ms, opacity 300ms, visibility 300ms;
    visibility: hidden;
    width: 3.75rem;
    z-index: 15;
  }
  .slider_overview .control_slides.show {
    opacity: 1;
    visibility: visible;
  }
  .slider_overview .control_slides:focus,
  .slider_overview .control_slides:hover {
    fill: #aa004f;
    outline: 0;
  }
  .slider_overview .control_slides .icon {
    height: 1.75rem;
    width: 1.75rem;
  }
  .slider_overview .prev_slide {
    left: 0;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .slider_overview .next_slide {
    right: 0;
  }
  .slider_overview .pagination {
    bottom: 1.53em;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 1;
  }
  .slider_overview .pagination ul:after {
    clear: both;
  }
  .slider_overview .pagination ul:after,
  .slider_overview .pagination ul:before {
    content: "";
    display: table;
  }
  .slider_overview .pagination ul li {
    float: left;
    margin-right: 0.625rem;
  }
  .slider_overview .pagination ul li:last-child {
    margin: 0;
  }
  .slider_overview .pagination ul li a {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    display: block;
    height: 1rem;
    -webkit-transition: 300ms;
    transition: 300ms;
    width: 1rem;
  }
  .slider_overview .pagination ul li a.active {
    background: #aa004f;
  }
  .button_career_aside {
    background-color: #006e43;
    border-bottom: 0.125rem solid #752157;
    border-top: 0.125rem solid #aa004f;
    color: #fff;
    margin-bottom: 1.53em;
    padding: 0.765em 1.53em;
  }
  .button_career_aside h2,
  .button_career_aside h3,
  .button_career_aside p {
    color: #fff;
    font-weight: normal;
  }
  .button_career {
    -webkit-box-align: center;
            align-items: center;
    background-color: #006e43;
    border-bottom: 0.125rem solid #752157;
    border-top: 0.125rem solid #aa004f;
    color: #fff;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0.765em 1.53em;
    position: relative;
    right: 0;
    text-align: center;
  }
  .button_career h2,
  .button_career h3 {
    color: #fff;
    font-weight: normal;
  }
  .button_career div {
    max-width: 100%;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    margin-bottom: 0.765em;
    margin-right: 0.87428571em;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    -webkit-box-align: center;
            align-items: center;
    color: #a3a4a6;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 162.5%;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .text {
    display: none;
  }
  .toggle_offcanvas_menu label .icon {
    height: 1.875rem;
    margin-left: 0.51em;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 1.625rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #006e43;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.1875rem;
    -webkit-transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, -webkit-transform 300ms;
    transition: top 300ms 300ms, transform 300ms;
    transition: top 300ms 300ms, transform 300ms, -webkit-transform 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 0.8125rem;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
    -webkit-transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, -webkit-transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms;
    transition: bottom 300ms 300ms, transform 300ms, -webkit-transform 300ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(20, 20, 20, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 240ms, visibility 240ms;
    transition: opacity 240ms, visibility 240ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu {
    background-color: #fff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    display: block;
    min-height: 100%;
    opacity: 0;
    padding: 1.53em 0;
    position: absolute;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, visibility 240ms, -webkit-transform 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms;
    transition: opacity 240ms, transform 240ms, visibility 240ms, -webkit-transform 240ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    width: 15.625rem;
    z-index: 5000;
    left: 0;
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.53em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a:after {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li.expanded > a ~ ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((1.25rem + 0
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.25rem;
    color: #141414;
    display: block;
    font-weight: 700;
    line-height: 2.75rem;
    outline-color: #141414;
    position: relative;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:after {
    background: #141414;
    border-radius: 0.1875rem;
    bottom: 0;
    content: "";
    display: none;
    height: 0.125rem;
    left: 0;
    right: 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a.active:after {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
    padding: 0.765em 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    color: #141414;
    display: block;
    outline-color: #141414;
    padding: 0.3825em 1.53em 0.3825em 0;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    font-weight: 700;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:focus,
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    text-decoration: underline;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.765em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.765em;
  }
  .onlinetools_popup_overview .touch_link .title {
    margin: 0 0 0.3825em;
    padding: 0;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(20, 20, 20, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms, visibility 300ms;
    transition: opacity 300ms, visibility 300ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.53em;
  }
  .popup_close {
    background: #aa004f;
    border-radius: 0.1875rem 0.1875rem 0 0;
    float: right;
    height: 2.2rem;
    margin-right: 0.0625rem;
    padding: 0.43714286em;
    text-align: center;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #fff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, visibility 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms;
    transition: opacity 300ms, transform 300ms, visibility 300ms, -webkit-transform 300ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #fff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(20, 20, 20, 0.5);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.2rem;
  }
  .popup_iframe {
    background: #fff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    background-color: #f7f7f7;
    cursor: move;
    height: 21.875rem;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(247, 247, 247, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.53em 3.06em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.53em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.53em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.53em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.765em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.53em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #aa004f;
    border: 0.125rem solid #aa004f;
    border-radius: 0.1875rem;
    color: #fff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 300ms, border 300ms, color 300ms;
    transition: background 300ms, border 300ms, color 300ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #dd0067;
    border: 0.125rem solid #dd0067;
    box-shadow: 0 0 0 0.375rem rgba(170, 0, 79, 0.2);
    color: #fff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #dd0067;
    border: 0.125rem solid #dd0067;
    color: #fff;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 2.75rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: transform 300ms, opacity 300ms;
    transition: transform 300ms, opacity 300ms, -webkit-transform 300ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.765em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li::before {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f7f7f7;
    border-radius: 0.1875rem;
    color: #141414;
    margin-bottom: 0.765em;
    padding: 1.02em 1.53em;
  }
  .opening_times .title {
    padding-top: 0.765em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.295em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 900ms linear infinite;
            animation: preloader_rotate 900ms linear infinite;
    display: block;
    fill: #dedede;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 300ms;
    transition: 300ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .sub_menu {
    background: #fff;
    border: 0.125rem solid #aa004f;
    border-radius: 0.1875rem;
    padding: 2.6775em 0;
    padding-left: -2.375rem;
    padding-right: -2.375rem;
  }
  .sub_menu .title {
    letter-spacing: calc((0.9375rem + 0.1875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.9375rem;
    font-weight: 400;
    padding: 0;
  }
  .sub_menu ul {
    list-style: url("../images/list_style_sub_menu.svg") !important;
    margin: 0 0 0.765em;
    padding-left: 1.1475em;
  }
  .sub_menu ul > li {
    padding-left: 0.3825em;
  }
  .sub_menu a {
    color: #141414;
    outline-color: #141414;
    text-decoration: none;
  }
  .sub_menu a.active,
  .sub_menu a:hover {
    text-decoration: underline;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.765em;
  }
  .team_overview .title {
    margin-bottom: 0.765em;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.53em 0.765em 0;
    max-width: 10.625rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.765em;
  }
  .team_entry .photo {
    margin-bottom: 1.53em;
    max-width: 10.625rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.765em;
  }
  .teampage figure {
    margin: 0 0 3.06em;
  }
  body.team_wolfgang h1,
  body.team_wolfgang h2 {
    color: #aa004f;
  }
  body.team_michael h1,
  body.team_michael h2 {
    color: #752157;
  }
  body.team_wanton h1,
  body.team_wanton h2 {
    color: #006e43;
  }
  .teaser_overview {
    margin-bottom: 3.825em;
  }
  .teaser {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 1.53em;
    padding-bottom: 2.125rem;
    text-decoration: none;
  }
  .teaser .title {
    color: #aa004f;
    text-align: center;
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
  }
  .teaser .image {
    height: 100%;
    line-height: 0;
    margin-top: auto;
    max-width: 100%;
    position: relative;
    width: 100%;
  }
  .teaser .image:before,
  .teaser .image:after {
    background: #fff;
    border: 0.125rem solid #752157;
    border-radius: 50%;
    bottom: -4rem;
    content: "";
    display: block;
    height: 7.75rem;
    left: 50%;
    margin-left: -4rem;
    position: absolute;
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
    width: 7.75rem;
    z-index: 2;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .teaser .image:after {
    border: 0.125rem solid #006e43;
    bottom: -4.25rem;
    height: 8.25rem;
    margin-left: -4.25rem;
    width: 8.25rem;
    z-index: 1;
  }
  .teaser .icon {
    background: #aa004f;
    border-radius: 50%;
    bottom: -3.75rem;
    display: block;
    fill: #fff;
    height: 7.5rem;
    left: 50%;
    margin-left: -3.75rem;
    padding: 1.5625rem;
    position: absolute;
    -webkit-transform: scale(0.65);
            transform: scale(0.65);
    -webkit-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
    width: 7.5rem;
    z-index: 3;
    -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .teaser .description {
    color: #141414;
    max-width: 47.5rem;
    text-align: center;
    padding-top: 3.75rem;
  }
  .teaser:focus .title,
  .teaser:hover .title {
    color: #141414;
  }
  .teaser:focus .image,
  .teaser:hover .image {
    line-height: 0;
    margin-top: auto;
    position: relative;
  }
  .teaser:focus .image:before,
  .teaser:hover .image:before {
    bottom: -2.75rem;
  }
  .teaser:focus .image:after,
  .teaser:hover .image:after {
    bottom: -3rem;
  }
  .teaser:focus .icon,
  .teaser:hover .icon {
    background: #752157;
    bottom: -2.5rem;
  }
  body.startpage .teaser .title {
    color: #141414;
  }
  body.startpage .teaser:focus .title,
  body.startpage .teaser:hover .title {
    color: #aa004f;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #f7f7f7;
    border: 0.125rem solid #dedede;
    border-radius: 0.1875rem;
    color: #141414;
    font-size: 85%;
    font-weight: 400;
    line-height: 2.0625rem;
    padding: 0 0.765em;
    position: absolute;
    right: 0;
    text-align: center;
    top: -2.4375rem;
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f7f7f7;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #dedede;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
}
@media screen and (min-width: 20rem) {
  [role="tab"] {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [role="tab"] {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  [role="tab"] .description {
    font-size: calc(0.875rem + 0.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [role="tab"] .description {
    font-size: 1.0625rem;
  }
}
@media screen and (min-width: 20rem) {
  aside.aside {
    padding-bottom: calc(2.8125rem + 2.8125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  aside.aside {
    padding-bottom: 5.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  aside.aside {
    display: block;
  }
}
@media screen and (min-width: 20rem) {
  .author_wrapper {
    margin-bottom: calc(0.625rem + 2.8125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_wrapper {
    margin-bottom: 3.4375rem;
  }
}
@media screen and (min-width: 20rem) {
  .author_hor {
    right: calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .author_hor {
    right: 3.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .logo_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 2.295em;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo {
    max-width: calc(10.3125rem + 7.8125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo {
    max-width: 18.125rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .company_logo {
    margin-left: calc(-0.625rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .company_logo {
    margin-left: 3.125rem;
  }
}
@media screen and (min-width: 35rem) and (min-width: 20rem) {
  .company_logo {
    margin-right: calc(-0.625rem + 3.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 35rem) and (min-width: 93.75rem) {
  .company_logo {
    margin-right: 3.125rem;
  }
}
@media screen and (max-width: 34.9375rem) {
  .company_logo.company_logo_2 {
    margin: 0.765em 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .dropdown_menu {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li:after {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li:after {
    font-size: 1.25rem;
  }
}
@media screen and (max-width: 93.6875rem) {
  .dropdown_menu > ul > li:last-child ul {
    left: auto;
    margin-left: 0;
    margin-right: -0.51em;
    right: 0;
    text-align: right;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    margin-left: calc(0.9375rem + 1.5625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    margin-left: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .dropdown_menu > ul > li > a {
    margin-right: calc(0.9375rem + 1.5625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .dropdown_menu > ul > li > a {
    margin-right: 2.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .page_footer .google_maps_overlay_wrapper {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 35rem) {
  .page_footer .google_maps_overlay_wrapper {
    z-index: 1;
  }
}
@media screen and (min-width: 93.75rem) {
  .page_footer .google_maps_overlay_wrapper {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .page_footer .google_maps_overlay {
    padding: 3.06em;
  }
}
@media screen and (min-width: 35rem) {
  .page_footer .google_maps_overlay .address .contact_info .contact {
    display: -webkit-box;
    display: flex;
  }
}
@media screen and (min-width: 35rem) {
  .page_footer .google_maps_overlay .address .contact_info .contact .label {
    display: inline;
  }
}
@media screen and (min-width: 35rem) {
  .page_footer .bottom {
    padding: 4.59em 0;
  }
}
@media screen and (min-width: 75rem) {
  .page_footer .links_overview {
    align-self: flex-end;
    margin: auto 0 1.53em auto;
  }
}
@media screen and (min-width: 20rem) {
  .page_footer .links_overview ul li:first-child:after {
    padding-left: calc(0.5rem + 0.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .page_footer .links_overview ul li:first-child:after {
    padding-left: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  .page_footer .links_overview ul li:first-child:after {
    padding-right: calc(0.625rem + 0.9375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .page_footer .links_overview ul li:first-child:after {
    padding-right: 1.5625rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .page_header .flex_container {
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    -webkit-box-pack: end;
            justify-content: flex-end;
    position: relative;
    right: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .page_header .flex_container {
    display: none;
  }
}
@media screen and (min-width: 35rem) {
  .page_header .call {
    margin-right: 1.02em;
  }
}
@media screen and (min-width: 20rem) {
  .header_picture .call {
    right: calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 61.875rem) {
  .header_picture .call {
    display: block;
  }
}
@media screen and (min-width: 93.75rem) {
  .header_picture .call {
    bottom: auto;
    left: auto;
    margin-top: -7.8125rem;
    right: 3.125rem;
    top: 0;
  }
}
@media screen and (min-width: 20rem) {
  .header_picture .call .text {
    font-size: calc(0.875rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .header_picture .call .text {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 20rem) {
  main {
    padding-bottom: calc(2.8125rem + 2.8125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  main {
    padding-bottom: 5.625rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .slider_overview .career_banner .career_banner_content .contact .additional_info {
    display: block;
  }
}
@media screen and (min-width: 35rem) {
  .slider_overview .career_banner .career_banner_content .greetings {
    display: block;
  }
}
@media screen and (min-width: 61.875rem) {
  .button_career {
    max-width: 80%;
  }
}
@media screen and (min-width: 61.875rem) {
  .button_career div {
    max-width: 80%;
  }
}
@media screen and (min-width: 47.5rem) {
  .toggle_offcanvas_menu {
    margin: 0;
  }
}
@media screen and (min-width: 61.875rem) {
  .toggle_offcanvas_menu {
    display: none;
  }
}
@media screen and (min-width: 30rem) {
  .toggle_offcanvas_menu label .text {
    display: block;
  }
}
@media screen and (max-width: 61.8125rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    opacity: 1;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_1 {
    top: 0.8125rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, -webkit-transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms;
    transition: top 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu label .line_3 {
    bottom: 0.8125rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, -webkit-transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms;
    transition: bottom 300ms, transform 300ms 300ms, -webkit-transform 300ms 300ms;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ header .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ header .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu {
    width: 21.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(1.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.53em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.53em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 20rem) {
  .sub_menu {
    padding-left: calc(-2.375rem + 7.0625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .sub_menu {
    padding-left: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .sub_menu {
    padding-right: calc(-2.375rem + 7.0625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .sub_menu {
    padding-right: 4.6875rem;
  }
}
@media screen and (min-width: 20rem) {
  .sub_menu .title {
    font-size: calc(0.9375rem + 0.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .sub_menu .title {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 30rem) {
  .team_entry .photo {
    float: left;
    margin: 0 1.53em 0.765em 0;
  }
}
@media screen and (min-width: 35rem) {
  .teaser {
    padding-bottom: 3.4375rem;
  }
}
@media screen and (min-width: 75rem) {
  .teaser {
    padding-bottom: 4.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .teaser .image:before,
  .teaser .image:after {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
}
@media screen and (min-width: 75rem) {
  .teaser .image:before,
  .teaser .image:after {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@media screen and (min-width: 35rem) {
  .teaser .icon {
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }
}
@media screen and (min-width: 75rem) {
  .teaser .icon {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@media screen and (min-width: 20rem) {
  .teaser .description {
    padding-top: calc(3.75rem + 2.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser .description {
    padding-top: 6.25rem;
  }
}
@media print {
  .company_logo {
    display: block;
    margin-bottom: 0.765em;
    max-width: 11.25rem !important;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.53em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.765em;
  }
  dd {
    margin: 0;
  }
  ul {
    list-style: url("../images/list_style_image_rectangle.svg") !important;
  }
  ul li:before {
    display: none!important;
  }
  ul ol,
  ul ul {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  .news_entity .hide_creation_date {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.53em;
  }
  .logo_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    margin-bottom: 0.765em;
  }
  .company_logo {
    display: block;
    width: 18.125rem;
  }
  .company_logo.company_logo_2 {
    margin: 0 3.06em;
  }
  .company_logo img {
    display: block;
  }
  .qr_code_print {
    border-top: 0.125rem solid;
    margin: 1.53em 0 0;
    padding: 0.765em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
